/** @jsx jsx */
import jsx from '../jsx'
import { Link } from 'gatsby'

import Logo from '../components/Logo'
import Container from '../components/Container'
import Layout from '../components/layout'
import NewsletterSignup from '../components/newsletter-signup'

export default () => {
  return (
    <Layout>
      <header px={3} py={3}>
        <Logo />
      </header>
      <main role="main" px={3} py={4}>
        <h1 my={0}>We can't find that page</h1>
        <h2>Maybe you're looking for one of these?</h2>
        <ul>
          <li>
            <Link color="black" to="/gradients">
              Gradients
            </Link>
          </li>
          <li>
            <Link color="black" to="/font-pairings">
              Font Pairings
            </Link>
          </li>
          <li>
            <Link color="black" to="/stripes">
              Stripes
            </Link>
          </li>
        </ul>
      </main>
      <footer px={4} py={3} borderTop="1px solid" borderColor="#eee">
        <div display="flex" alignItems="center" fontSize={0}>
          <small mr="auto">© Copyright 2018-2019</small>
          <a ml="auto" mr={3} href="/terms" title="Terms">
            Terms
          </a>
          <a href="/privacy" title="Privacy">
            Privacy
          </a>
        </div>
      </footer>
    </Layout>
  )
}
